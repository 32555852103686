import { useAuth } from '@context/AuthContext';
import { useI18n } from '@core/hooks/useI18n';
import { useNavigationConfig } from '@core/hooks/useNavigationConfig';
import { getGuardedNavigation } from '@core/utils/getGuardedNavigation';
import { syncRefreshToken } from '@core/utils/shouldRefreshToken';
import usePostChangeLanguage from '@hooks/usePostChangeLanguage';
import { LanguageType } from '@interface/UserInterface';
import { getInitialCompanyNameForFallbackAvatar } from '@utils/getInitialCompanyNameForFallbackAvatar';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Sidebar, SidebarMenuInterface } from 'uangcermat-web-toolkit-v2';

interface SidebarLayoutInterface {
  children: React.ReactNode;
}

const SidebarLayout = ({ children }: SidebarLayoutInterface) => {
  const router = useRouter();
  const [activeId, setActiveId] = useState<string>('/dashboard');
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const { t } = useTranslation(['menu']);

  const { logout, userAuth, activeCompany } = useAuth();
  const { DATA_SIDEBAR } = useNavigationConfig();

  const guardedNavigation = getGuardedNavigation({
    navConfig: DATA_SIDEBAR,
    userPermission: userAuth?.permissions ?? []
  });

  const { changeLanguage } = useI18n();
  const { mutateAsync: mutateAsyncChangeLanguage } = usePostChangeLanguage({
    onSuccess: () => {
      syncRefreshToken();
    }
  });

  const [currentLanguage, setCurrentLanguage] = useState<LanguageType>(userAuth?.lang ?? 'en');

  const toggleChangeLanguage = () => {
    const toLanguage = currentLanguage === 'en' ? 'id' : 'en';

    mutateAsyncChangeLanguage({ lang: toLanguage });

    setCurrentLanguage(toLanguage);
    changeLanguage(toLanguage);
  };

  return (
    <div className="flex flex-row w-screen h-screen bg-gray-100">
      <Sidebar
        activeId={router.asPath.split('?')[0] || activeId}
        itemMenuContainerStyles="overflow-y-auto scrollbar-none"
        activeParent="payrollCycle"
        isCollapsed={isCollapsed}
        data={guardedNavigation as SidebarMenuInterface[]}
        onClickNav={(navLink) => {
          setActiveId(navLink.navLink as string);
          router.push(navLink.navLink as string);
        }}
        itemMenuTitleStyles="font-normal"
        onClickCollapsible={() => {
          setIsCollapsed(!isCollapsed);
        }}
        containerStyles={`${isCollapsed ? 'w-14' : 'w-[220px] md:w-[274px]'} min-h-screen`}
      >
        <Sidebar.Header
          isCollapsed={isCollapsed}
          appLogoOpenedStyles="w-[123.2px] h-[32px] top-[12px] left-[16px] gap-0"
          onClickHeaderSidebar={() => router.push('/dashboard')}
          appLogoOpened="/icons/favicon-with-text.svg"
          appLogoClosed="/icons/favicon-32x32.png"
        />

        <Sidebar.UserProfile
          isCollapsed={isCollapsed}
          userLabel={userAuth ? userAuth.firstName : ''}
          userAvatarFallback={userAuth ? userAuth.firstName[0].toUpperCase() : ''}
          userLabelAtPanel={userAuth ? `${userAuth.firstName} ${userAuth.lastName}` : ''}
          userAvatarFallbackAtPanelStyles="text-[12px]"
          userLabelAtPanelStyles="font-semibold"
          userAvatarFallbackAtPanel={userAuth ? `${userAuth.firstName[0].toUpperCase()}` : ''}
          userEmailAtPanel={userAuth ? userAuth.email : ''}
          userAvatarAtPanelStyles="flex-grow-0  flex-shrink-0"
          containerStyles="z-30"
          onClickTranslation={() => {
            toggleChangeLanguage();
          }}
          userEmailAtPanelStyles="mt-1"
          onClickLogout={() => logout()}
          manageAccountContainerStyles="hidden"
          translationLabelAtPanel={t('menu:profile.translatePage')}
          logoutLabelAtPanel={t('menu:profile.logout')}
          titleAtPanel={t('menu:profile.profile')}
          position="right"
          offsetY={-64}
          userLabelStyles="font-normal"
        />

        <Sidebar.Footer
          appName="&copy;&nbsp;PinjamCermat"
          appVersion={`Ver. ${process.env.APP_VERSION}`}
          companyName={activeCompany ? activeCompany.name : ''}
          companyLogo={activeCompany ? activeCompany.logo : ''}
          companyLogoFallback={
            activeCompany
              ? getInitialCompanyNameForFallbackAvatar({ name: activeCompany.name })
              : ''
          }
          isCollapsed={isCollapsed}
        />
      </Sidebar>

      {children}
    </div>
  );
};

export default SidebarLayout;
