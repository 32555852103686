import '../styles/globals.css';
import '../styles/font.css';
import '../../node_modules/uangcermat-web-toolkit-v2/build/index.css';

import AppContext from '@context/AppContext';
import { ToastProvider } from '@context/ToastContext';
import { useI18n } from '@core/hooks/useI18n';
import SidebarLayout from '@layout/SidebarLayout';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createGlobalStyle } from 'styled-components';
import { ColorDark, ColorLight } from 'uangcermat-web-toolkit-v2';

export const GlobalStyle = createGlobalStyle`
.rs-calendar-table-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content, .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content, .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content, .rs-calendar-table-cell-disabled .rs-calendar-table-cell-content:hover, .rs-calendar-table-cell-disabled .rs-calendar-table-header-cell-content:hover, .rs-calendar-table-header-cell-disabled .rs-calendar-table-cell-content:hover, .rs-calendar-table-header-cell-disabled .rs-calendar-table-header-cell-content:hover {
  text-decoration: unset !important
}

::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}

input[name="invoice_type"] {
  width: auto;
}

::-webkit-scrollbar-track {
  position: absolute;
  background: #FFFFFF;
  box-shadow: inset 0px 0px 17px rgba(0, 0, 0, 0.25);
}

::-webkit-scrollbar-thumb {
  position: absolute;
  background: ${ColorDark.blackCoral}; 
  border-radius: 23px;
}

.scrollbar-thumb-dark-blackCoral {
  --scrollbar-thumb: ${ColorDark.blackCoral} !important;
  --scrollbar-track: inset 0px 0px 17px rgba(0, 0, 0, 0.25) !important;
}

.form-login-email {
  p {
    font-size: 10px;
    line-height: 12px;
  }
}
.form-login-password {
  p {
    font-size: 10px;
    line-height: 12px;
  }
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  opacity: 0.3 !important;
}

.rs-calendar-table-cell-disabled.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content:hover {
  background-color: ${ColorLight.aliceBlue} !important;
  color: inherit !important;
  opacity: 0.3 !important;
}
`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retryOnMount: false
    }
  }
});

// eslint-disable-next-line react-hooks/rules-of-hooks
const { init } = useI18n();

init();

function MyApp({ Component, pageProps, err }: AppProps & { err: unknown }) {
  const [showChild, setShowChild] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  const ComponentWrapper = () => {
    return <Component {...pageProps} err={err} />;
  };

  return (
    <>
      <Head>
        <title>{`${process.env.APP_NAME} Client Portal`}</title>
      </Head>
      <GlobalStyle />
      <QueryClientProvider client={queryClient}>
        <ToastProvider>
          <AppContext>
            {['/', 'login', 'onboardingCompany'].includes(router.pathname.split('/')[1]) ? (
              <ComponentWrapper />
            ) : (
              <SidebarLayout>
                <ComponentWrapper />
              </SidebarLayout>
            )}
            {/* <Toast /> */}
          </AppContext>
        </ToastProvider>
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
